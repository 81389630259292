import React, { useState, useEffect } from 'react';

function Cookie() {
    const [showConsent, setShowConsent] = useState(false);

    useEffect(() => {
        // Проверяем, давал ли пользователь согласие ранее
        const consentGiven = localStorage.getItem('cookieConsent');
        if (!consentGiven) {
            setShowConsent(true);
        }
    }, []);

    const handleAccept = () => {
        // Сохраняем согласие пользователя в localStorage
        localStorage.setItem('cookieConsent', 'true');
        setShowConsent(false);
    };

    if (!showConsent) return null;

    return (
        <div className="consentBanner">
            <p>Мы используем файлы cookie для улучшения вашего опыта на сайте. Продолжая использование сайта, вы соглашаетесь с нашей <a href="/privacy-policy" className="link">политикой конфиденциальности</a>.</p>
            <button onClick={handleAccept} className="acceptButton">Принять</button>
        </div>
    );
}

export default Cookie;
