import React from 'react';
import Gallery from './FirstGallery';

function Policy() {
    return (
        <div>
            <h1>Политика конфиденциальности</h1>
            <div className='slide-box-two'>
                <div className='text'>
                    <p>Ваше право на конфиденциальность важно для нас. В этой политике конфиденциальности объясняется, как мы обрабатываем информацию, которую вы можете предоставить на сайте моей личной галереи картин (далее — «Сайт»). Пожалуйста, внимательно ознакомьтесь с настоящей политикой, чтобы понять наши принципы обработки персональных данных.</p>
                    <p><b>1. Информация, которую мы собираем</b></p>
                    <p><b>1.1</b> Личные данные<br/>Мы не собираем никакой личной информации на этом сайте через формы или другие интерактивные элементы. Сайт предоставляет возможность связаться с нами через Telegram, что осуществляется по внешней ссылке. При использовании Telegram могут применяться собственные политики конфиденциальности этой платформы.</p>
                    <p><b>1.2</b> Cookie и анонимные данные<br/>Наш сайт может использовать файлы cookie для улучшения его работы и анализа посещаемости. Файлы cookie представляют собой небольшие текстовые файлы, которые сохраняются на вашем устройстве и помогают нам анализировать, как посетители используют сайт. Эти данные являются полностью анонимными и не позволяют установить личность пользователя.</p>
                    <p>Какие файлы cookie мы используем:<br/>
                    Необходимые cookie: Эти файлы cookie обеспечивают работу сайта и его основных функций.<br/>
                    Аналитические cookie (если применимо): Мы можем использовать аналитические cookie для получения обезличенной информации о посещаемости сайта, чтобы улучшать его содержание и структуру.</p>
                    <p><b>2. Как мы используем собранную информацию</b><br/>
                    Собранная информация используется исключительно для:<br/>
                    Обеспечения корректной работы сайта;<br/>
                    Анализа посещаемости сайта и улучшения его содержания и пользовательского опыта.</p>
                    <p><b>3. Ссылки на другие сайты</b><br/>
                    Сайт может содержать ссылки на внешние ресурсы, такие как Telegram. Мы не несем ответственности за содержание и политику конфиденциальности этих сторонних сайтов. Рекомендуем ознакомиться с их политикой конфиденциальности, прежде чем предоставлять личную информацию.</p>
                    <p><b>4. Обновления и изменения политики</b><br/>
                    Мы можем периодически вносить изменения в настоящую политику конфиденциальности, и обновлённая версия будет размещена на этой странице. Мы рекомендуем время от времени просматривать этот документ для получения актуальной информации о нашей политике.</p>
                    <p><b>5. Контактная информация</b><br/>
                    Если у вас есть вопросы по поводу настоящей политики конфиденциальности, вы можете связаться с нами через <a className='link' href="https://t.me/GalleryMart_Bot">Telegram-бота</a>.</p>
                </div>
            </div>
            <div className='content'>
                    <Gallery></Gallery>
            </div>
        </div>
    );
}

export default Policy;