import React from 'react';

function Footer() {
    return (
        <footer>
            <div><span>&copy; Gallery Mart 2024</span> <a href="/privacy-policy" className="link">Политика конфиденциальности</a></div>
        </footer>
    );
}

export default Footer;